import { Link } from "gatsby"
import React from "react"
import {Container,Row,Col} from "react-bootstrap"
import * as styles from "./LegacySystem.module.scss"

const LegacySystem = ({ strapiData }) => {
  return (
    <div className={styles.dedicatedBanner}>
      <Container className={styles.innerDedicated}>
        <div className={styles.dedicated}>
          <Container>
          <Row>
            <Col xs={12} md={6} className={styles.left}>
              <div className={styles.dedicatedHeading}>
                <h2
                  className={styles.bannerHeading}
                  dangerouslySetInnerHTML={{
                    __html: strapiData?.title,
                  }}
                />
               
              </div>
              <div className={styles.newBtn}>
                {strapiData?.buttons[0] && (
                  <Link to={strapiData?.buttons[0]?.url}>
                    <p>{strapiData?.buttons[0]?.title}</p>
                  </Link>
                )}
              </div>
            </Col>
            <Col xs={12} md={6} className={styles.right}>
            <p
                  className={styles.description}
                  dangerouslySetInnerHTML={{
                    __html: strapiData?.description?.description,
                  }}
                />
            </Col>
          </Row>
         
          </Container>
        </div>
      </Container>
    </div>
  )
}

export default LegacySystem