import { Link } from "gatsby"
import React from "react"
import {Container,Row,Col} from "react-bootstrap"
import * as styles from "./Banner.module.scss"

const Banner = ({ strapiData }) => {
  return (
    <div className={styles.dedicatedBanner}>
      <Container className={styles.innerDedicated}>
        <div className={styles.dedicated}>
          <Container>
          <Row>
            <Col xs={12} md={6} className={styles.left}>
              <div className={styles.dedicatedHeading}>
                <p
                  className={styles.subTitle}
                  dangerouslySetInnerHTML={{
                    __html: strapiData?.title,
                  }}
                />
                <h1
                  className={styles.bannerHeading}
                  dangerouslySetInnerHTML={{
                    __html: strapiData?.subTitle,
                  }}
                />
                 {/* <h1
                  className={styles.bannerHeading}
                  dangerouslySetInnerHTML={{
                    __html: strapiData?.subTitle2,
                  }}
                /> */}
                <p
                  className={styles.description}
                  dangerouslySetInnerHTML={{
                    __html: strapiData?.description?.description,
                  }}
                />
              </div>
              <div className={`blueButton`}>
                {strapiData?.buttons[0] && (
                  <Link to={strapiData?.buttons[0]?.url}>
                    <p>{strapiData?.buttons[0]?.title}</p>
                  </Link>
                )}
              </div>
            </Col>
            <Col xs={12} md={6} className={styles.right}>
              <img src={strapiData?.secImages[0]?.localFile?.publicURL} alt=""/>
            </Col>
          </Row>
         
          </Container>
        </div>
      </Container>
      <img
        className={styles.curve}
        src="https://invozone-backend.s3.us-east-1.amazonaws.com/line_vector_c415196118.png"
      />
    </div>
  )
}

export default Banner